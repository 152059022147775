import workspace from '@/plugins/axios/workspace'
import { sanitizePersona, sanitizePersonas } from './personasSanitizers';
import { Persona } from '@/types/persona.types';

function formatDataForServer(data: Partial<Persona>) {
  const { enableFollowUps, ...rest } = data;

  return {
    ...rest,
    settings: {
      enableFollowUps,
    },
  }
}

export const personasService = {
  async fetchAll(workspaceId: string) {
    const response = await workspace.get(`${workspaceId}/personas`);

    return sanitizePersonas(response?.data?.dto);
  },

  async create(workspaceId: string, data: Omit<Persona, 'id' | 'publishedAt' | 'updatedAt' | 'enableFollowUps'>) {
    const response = await workspace.post(`${workspaceId}/personas`, data);

    return sanitizePersona(response?.data?.dto);
  },

  async patch(workspaceId: string, personaId: string,  data: Omit<Persona, 'id' | 'skills' | 'publishedAt' | 'updatedAt'>) {
    const response = await workspace.patch(`${workspaceId}/personas/${personaId}`, formatDataForServer(data));

    return sanitizePersona(response?.data?.dto);
  },

  async publish(workspaceId: string, personaId: string) {
    const response = await workspace.post(`${workspaceId}/personas/${personaId}/publish`);

    return sanitizePersona(response?.data?.dto);
  },

  async enable(workspaceId: string, personaId: string) {
    const response = await workspace.post(`${workspaceId}/personas/${personaId}/enable`);

    return sanitizePersona(response?.data?.dto);
  },

  async disable(workspaceId: string, personaId: string) {
    const response = await workspace.post(`${workspaceId}/personas/${personaId}/disable`);

    return sanitizePersona(response?.data?.dto);
  }
}
