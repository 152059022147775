import { SkillType } from "@/types/skill.types";
import { Persona } from "@/types/persona.types";
import { Expect } from "@/utils/Expect";

const sanitizeSkills = (items?: any): SkillType[] => {
  const skills: SkillType[] = [];

  Expect.array(items).forEach(item => {
    const skillType = Expect.enum(item?.type, SkillType);

    if (skillType) {
      skills.push(skillType);
    }
  });

  return skills;
}

export const sanitizePersona = (item?: any): Persona => {
  return {
    id: Expect.string(item?.id),
    name: Expect.string(item?.name),
    avatar: Expect.string(item?.avatar),
    personality: Expect.string(item?.personality),
    writingStyle: Expect.string(item?.writingStyle),
    tones: Expect.array(item?.tones).map(tone => Expect.string(tone)),
    emojis: Expect.array(item?.emojis).map(emoji => Expect.string(emoji)),
    skills: sanitizeSkills(item?.skills),
    updatedAt: Expect.date(item?.updatedAt) || null,
    publishedAt: Expect.date(item.publishedAt) || null,
    enabled: Expect.boolean(item.enabled),
    enableFollowUps: Expect.boolean(item.settings?.enableFollowUps),
  };
};

export const sanitizePersonas = (items?: any): Persona[] => {
  return Expect.array(items).map(item => sanitizePersona(item));
}
