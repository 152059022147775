<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useNotifications } from '@/utils/useNotifications'
import { useAgentPreferencesStore } from '@/stores/useAgentPreferencesStore';
import { useFeaturesStore } from './stores/useFeaturesStore';
import { useAgentStore } from './stores/useAgentStore';

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl } = useThemeConfig()

const { global } = useTheme()

const initNotifications = useNotifications();
const featuresStore = useFeaturesStore();
const agentPreferencesStore = useAgentPreferencesStore();
const agentStore = useAgentStore();
const route = useRoute();
const router = useRouter();
const { features } = storeToRefs(featuresStore);
const { currentAgent } = storeToRefs(agentStore);

const { isAnyNotificationEnabled } = storeToRefs(agentPreferencesStore);
const didInitNotifications = ref(false);
// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

watch(() => !!currentAgent.value, (isSignedIn) => {
  if (isSignedIn) {
    featuresStore.fetchFeatures();
  }
}, { immediate: true });

watchEffect(async () => {
  if (isAnyNotificationEnabled.value && !didInitNotifications.value) {
    didInitNotifications.value = true;

    try {
      await initNotifications();
    } catch(e) {
      // ignore error
    }

  }
});

watchEffect(() => {
  if (!route.meta.feature || !features.value) return;

  const routeFeature = features.value[route.meta.feature];

  if (!routeFeature) {
    console.error(`${route.meta.feature} missing from feature flags`);

    return;
  }

  if (!routeFeature.value) {
    router.replace('/');
  }
});

const isProduction = import.meta.env.VITE_ENV === 'prod';
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <SnackbarConnected />
      <FrontendObservability v-if="isProduction" />
    </VApp>
  </VLocaleProvider>
</template>
