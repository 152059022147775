import workspace from '@/plugins/axios/workspace'
import { CurrentWorkspace } from '@/utils/current-workspace'
import { useSnackbarStore } from './useSnackbarStore'
import { Expect } from '@/utils/Expect'

export enum AssetUploadLocation {
    ORGANIZATION = 'organization',
    AGENTS = 'agents',
    TEMPLATES = 'templates',
    CONVERSATIONS = 'conversations',
    STORIES = 'stories',
    PERSONAS = 'personas',
}

interface State {
  currentWorkspaceId: string | undefined;
  isUploading: boolean;
}

export const useAssetStore = defineStore('useAssetStore', {
  state: (): State => ({
    currentWorkspaceId: undefined,
    isUploading: false,
  }),
  actions: {
    async upload(origin: AssetUploadLocation, file: File): Promise<string | undefined> {
        const snackbarStore = useSnackbarStore();
        const formData = new FormData()

        formData.append('file', file)

        this.isUploading = true;

        const path = await this.buildPath(`assets/${origin}`)
        const response = await workspace.post(path, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(snackbarStore.handleError)

        this.isUploading = false;

        return response?.data?.assetUrl;
    },
    async parseCsv(file: File) {
      const snackbarStore = useSnackbarStore();
      const formData = new FormData()
      formData.append('file', file)

      const path = await this.buildPath(`assets/parse/csv`)
      const response = await workspace.post(path, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(snackbarStore.handleError)
      
      return Expect.array(response?.data?.result);
    },
    async buildPath(route: string) {
      this.currentWorkspaceId = await CurrentWorkspace.getWorkspace(this.currentWorkspaceId)
      return CurrentWorkspace.buildPath(route, this.currentWorkspaceId)
    },
  },
})
