<script lang="ts" setup>
import { useAgentStore } from '@/stores/useAgentStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { uuid } from "@/utils/uuid";
import { createSession, faro, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

const agentStore = useAgentStore();
const workspaceStore = useWorkspaceStore();

const { currentAgent } = storeToRefs(agentStore);
const { currentWorkspaceId } = storeToRefs(workspaceStore);

const route = useRoute();

async function init() {
  if (faro.api) return;

  try {
    initializeFaro({
      url: import.meta.env.VITE_FARO_URL,
      app: {
        name: 'app-ui',
        environment: import.meta.env.VITE_ENV || 'dev',
        version: '0.0.1',
      },
      instrumentations: [
        ...getWebInstrumentations(),
        new TracingInstrumentation(),
      ],
      sessionTracking: {
        generateSessionId() {
          const id = `${currentAgent.value?.email}_${currentWorkspaceId.value}_${uuid()}`;

          return id;
        },
      },
    });
  } catch (e) {
    console.error('Failed to initialize Faro', e);
  }
}

watch([currentWorkspaceId, currentAgent], async ([workspaceId, agent]) => {
  if (!workspaceId || !agent) {
    faro.api?.resetUser();

    return;
  }

  await init();

  faro.api?.setUser({
    email: agent.email,
    id: agent.id,
  });

  faro.api?.setSession(createSession({ workspaceId }));
});

watch(() => route.fullPath, (url) => {
  faro.api?.pushEvent('navigation', { url });
});
</script>
