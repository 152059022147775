import { OnDataFunction } from '@/plugins/axios/requestService'
import workspace from '@/plugins/axios/workspace'
import { FeedActionEventType } from '@/types/feed.types'
import { CurrentWorkspace } from '@/utils/current-workspace'
import { uuid } from "@/utils/uuid";
import { KeyValuePair } from  "@/types/utility.types"
import { usePingPong } from '@/utils/usePingPong'

export interface StreamObject {
  id: string
  path: string
  onData: OnDataFunction
  backoff: number
  forceClose: boolean
}

interface State {
  feedStream: StreamObject | undefined
  messageStream: StreamObject | undefined
  broadcastStream: StreamObject | undefined
  aiPreviewStream: StreamObject | undefined
  contactUploadStream: StreamObject | undefined
  currentWorkspaceId: string
  baseUrl: string
  sockets: KeyValuePair<WebSocket | undefined>
}

export const useStreamStore = defineStore('streamStore', {
  state: (): State => ({
    feedStream: undefined,
    messageStream: undefined,
    broadcastStream: undefined,
    aiPreviewStream: undefined,
    contactUploadStream: undefined,
    currentWorkspaceId: '',
    baseUrl: import.meta.env.VITE_API_WORKSPACE_ENDPOINT,
    sockets: {}
  }),
  actions: {
    async connectFeed(onData: OnDataFunction) {
      this.closeConnection(this.feedStream);

      const path = await this.buildPath('stream/feeds')
      const id = uuid();
      this.feedStream = await this.connect({ id: id, path: path, onData: onData, forceClose: false, backoff: this.feedStream?.backoff || STARTING_BACKOFF })
    },

    async connectAIPreview(personaId: string, onData: OnDataFunction) {
      this.closeConnection(this.aiPreviewStream);

      const path = await this.buildPath(`ai/personas/${personaId}/preview`)
      const id = uuid();
      this.aiPreviewStream = await this.connect({ id: id, path: path, onData: onData, forceClose: false, backoff: this.aiPreviewStream?.backoff || STARTING_BACKOFF })
    },

    async connectUploadStream(onData: OnDataFunction, onOpen:  (ev: Event) => any) {
      this.closeConnection(this.contactUploadStream);

      const path = await this.buildPath(`contacts/upload/stream`)
      const ws = workspace.websocket(path)
      const id = uuid()
      this.contactUploadStream = { id: id, path: path, onData: onData, forceClose: false, backoff: this.contactUploadStream?.backoff || STARTING_BACKOFF }
      this.sockets[id] = ws

      ws.onopen = onOpen
      ws.onmessage = onData
    },

    async connectMessages(feedId: string, onData: OnDataFunction) {
      this.closeConnection(this.messageStream);

      const path = await this.buildPath(`stream/feed/${feedId}/events`)
      const id = uuid()
      this.messageStream = await this.connect({ id: id, path: path, onData: onData, forceClose: false, backoff: this.messageStream?.backoff || STARTING_BACKOFF })
    },

    async connectBroadcastStatusUpdates(onData: OnDataFunction) {
      this.closeConnection(this.broadcastStream);

      const path = await this.buildPath(`stream/broadcasts`)
      const id = uuid()
      this.broadcastStream = await this.connect({ id: id, path: path, onData: onData, forceClose: false, backoff: this.broadcastStream?.backoff || STARTING_BACKOFF })
    },

    closeConnection(stream?: StreamObject) {
      if (!stream) return;

      if (!this.sockets[stream.id]) return;

      this.sockets[stream.id]?.close(1000);

      delete this.sockets[stream.id];
    },

    sentMessageToPreview(text: string) {
      this.sockets[this.aiPreviewStream?.id || '']?.send(JSON.stringify({
        text,
        type: 'message'
      }));
    },

    clearAiPreview() {
      this.sockets[this.aiPreviewStream?.id || '']?.send(JSON.stringify({
        type: 'clear'
      }));
    },

    sendTypingEvent(feedId: string | undefined, currentAgentId: string, event: FeedActionEventType) {
      if(feedId) {
        this.sockets[this.feedStream?.id || '']?.send(JSON.stringify({
          id: feedId,
          type: 'FEED_ACTION_EVENT',
          action: {
              type: event,
              data: currentAgentId
          }
        }))
      }
    },

    async connect(streamObject?: StreamObject): Promise<StreamObject> {    
      const [ping, isPong] = usePingPong();

      return new Promise((resolve) => {
        if(streamObject) {
          setTimeout(() => {
            streamObject.backoff = streamObject.backoff + (streamObject.backoff * 0.2)

            const ws = workspace.websocket(streamObject.path)

            this.sockets[streamObject.id] = ws

            ws.onmessage = (event) => {
              if (isPong(event)) {
                ping(ws);

                return;
              }

              streamObject.onData(event);
            } 

            ws.onopen = () => {
              streamObject.backoff = STARTING_BACKOFF
              return resolve(streamObject)
            }
    
            ws.onclose = async (ev: CloseEvent) => {
              if (ev.code === 1000) return;

              return resolve(await this.connect(streamObject))
            }

            ping(ws);
          }, streamObject.backoff)
        } else {
          throw new Error('No stream object provided in Stream Store')
        }
      })
    },

    async buildPath(route: string) {
      this.currentWorkspaceId = await CurrentWorkspace.getWorkspace(this.currentWorkspaceId)
      const wsBaseUrl = this.baseUrl.replace('http://', 'ws://').replace('https://', 'wss://')
      const path = await CurrentWorkspace.buildPath(route, this.currentWorkspaceId)
      return `${wsBaseUrl}/${path}`
    },
  },
})

const STARTING_BACKOFF = 1000
