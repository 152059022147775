<script lang="ts" setup>
import { formatDateToMonthShort } from '@/@core/utils/formatters';
import { useT } from '@/locales/useT';

interface Props {
  isPinned: boolean;
  isExpiringSoon: boolean;
  unrepliedCount: number;
  date: Date | string;
}

const props = defineProps<Props>();

const t = useT();

const datetimeFormatted = computed(() => {
  const short = formatDateToMonthShort(`${props.date}`, false);

  if (short === '0 s') {
    return t('now');
  }

  return short;
});
</script>

<template>
  <div class="d-flex flex-column justify-space-between align-end">
    <div
      class="d-flex align-center"
      style="gap: 2px"
    >
      <VIcon
        v-if="isPinned"
        icon="mingcute:star-line"
        color="#1B1B1B"
        size="15"
        style="margin-top: -1px;"
      />
      <VIcon
        v-if="isExpiringSoon"
        icon="mingcute:stopwatch-line"
        color="black"
        size="17"
      />
      <Badge
        v-if="unrepliedCount > 0" 
        :count="unrepliedCount" 
        :size="{ width: 15, height: 15 }"
        background-color="#646464" 
        font-color="#fff"
        class="app-feed-card-details__badge"
      />
    </div>
    <AppText
      class="chat-feed-card__datetime"
      text-style="text-small"
      color="neutral-600"
      :text="datetimeFormatted"
    />
  </div>
</template>

<style scoped>
  .app-feed-card-details__badge :deep(> span) {
    font-size: 10px !important;
    line-height: 1em;
    padding-top: 1px;
  }

  .chat-feed-card__datetime {
    line-height: 14px;
    white-space: nowrap;
  }
</style>
