import { IntegrationProvider } from "@/types/integration.types"

export enum SkillType {
  CONCIERGE = 'CONCIERGE',
  CUSTOM_INTENT = 'CUSTOM_INTENT',
  OUT_OF_OFFICE = 'OUT_OF_OFFICE',
  CUSTOMER_SURVEY = 'CUSTOMER_SURVEY',
  UPSELL_HERO = 'UPSELL_HERO',
  PRODUCT_RECOMMENDATION = 'PRODUCT_RECOMMENDATION',
  FEEDBACK_COLLECTION = 'FEEDBACK_COLLECTION',
  ORDER_MANAGEMENT = 'ORDER_MANAGEMENT',
  SCHEDULING_ASSISTANT = 'SCHEDULING_ASSISTANT',
  SIGNALS = 'SIGNALS',
  CUSTOM = 'CUSTOM',
}

export enum SkillCategory {
  MARKETING = 'Marketing',
  SUPPORT = 'Support',
  SALES = 'Sales',
  AI = 'AI'
}

export enum SkillAvailability {
  COMING_SOON = 'COMING_SOON',
  CLOSED_BETA = 'CLOSED_BETA',
  OPEN_BETA = 'OPEN_BETA',
  GENERAL_RELEASE = 'GENERAL_RELEASE',
  DEPRECATED = 'DEPRECATED'
}

export interface Skill {
  id?: string
  type: SkillType
  isAvailable: boolean
  isEnabled: boolean
  isConfigured: boolean
  isRequestable: boolean
  availability: SkillAvailability
  categories: SkillCategory[]
  imageUrl: string
  imageAlt: string
  title: string
  description: string
  description2?: string
  resolutionRate?: string,
  actions: [string, string][],
  requiredIntegrations: IntegrationProvider[],
  url?: string,
  entity?: { id: string, state?: string }
}

export type SkillTypeContentFields =
    Omit<Skill, 'id' | 'type' | 'isAvailable' | 'isEnabled' | 'isConfigured' | 'isRequestable' | 'categories' | 'entity' | 'availability'>;
