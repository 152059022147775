const HEARTBEAT_INTERVAL_MS = 5000;

type PingFunc = (ws: WebSocket) => void;
type PongFunc = (event: MessageEvent<any>) => boolean;

export function usePingPong(): [PingFunc, PongFunc] {
  function ping(ws: WebSocket) {
    setTimeout(() => {
      if (ws.readyState !== ws.OPEN) return;

      ws.send(JSON.stringify({ ping: true }));
    }, HEARTBEAT_INTERVAL_MS);
  }

  function isPong(event: MessageEvent<any>): boolean {
    let parsedData;

    try {
      parsedData = JSON.parse(event.data);
    } catch(e) {
      // ignore error
    }

    return !!parsedData && parsedData.pong === true;
  }

  return [ping, isPong];
}
