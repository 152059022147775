<script lang="ts" setup>
import { useAgentPreferencesStore } from '@/stores/useAgentPreferencesStore';
import { useInboxStore } from '@/stores/useInboxStore';
import { ContactTagDto } from '@/types/contact.types';
import { Feed } from '@/types/feed.types';
import WhatsAppPng from '@/assets/logos/whatsapp/whatsapp.png';
import LiveChatActive from '@/assets/icons/livechat_active.png';
import LiveChatIdle from '@/assets/icons/livechat_idle.png';
import { Expect } from '@/utils/Expect';
import { hoursBetweenNow } from '@/utils/hoursBetweenNow';
import { useMinLoading } from '@/utils/useMinLoading';
import { useInboxCurrentFeedStore } from '@/stores/useInboxCurrentFeedStore';
import { useAgentStore } from '@/stores/useAgentStore';
import { usePersonasStore } from '@/stores/usePersonasStore';

interface Props {
  item: Feed;
  isActive: boolean;
  isSelected: boolean;
  hasSelection: boolean;
  disableSelection: boolean;
}

const props = defineProps<Props>();

const agentsStore = useAgentStore();
const agentPreferencesStore = useAgentPreferencesStore();
const inboxCurrentFeedStore = useInboxCurrentFeedStore();
const inboxStore = useInboxStore();
const personasStore = usePersonasStore();

const { persona } = storeToRefs(personasStore);
const { feed: currentFeed, feedLoadingError }  = storeToRefs(inboxCurrentFeedStore)
const { contacts, counts, conversations } = storeToRefs(inboxStore);
const { pinnedFeeds } = storeToRefs(agentPreferencesStore);
const { agentsById } = storeToRefs(agentsStore);

const router = useRouter();
const { getLoading, setLoading } = useMinLoading();

watchEffect(() => {
  const paramFeedId = router.currentRoute.value?.params?.feedId;

  setLoading.value = paramFeedId === props.item.id && paramFeedId !== currentFeed.value?.id && !feedLoadingError.value;
});

const contactTags = computed((): ContactTagDto[] => {
  const contact = props.item.contact_id && contacts.value[props.item.contact_id];
  const tags =  contact ? contact.tags : [];
  const filteredTags = tags.filter(tag => tag.category === 'SUPPORT');

  filteredTags.sort((t1: ContactTagDto, t2: ContactTagDto) => t1.label > t2.label ? 1 : -1)

  return filteredTags;
});

const contactName = computed((): string => {
  const contact = props.item.contact_id && contacts.value[props.item.contact_id];

  if (contact && contact.firstName) {
    return `${contact.firstName} ${contact.lastName}`;
  }

  return props.item.channel_user_name || props.item.external_user_id;
});

const isPinned = computed((): boolean => {
  return pinnedFeeds.value.includes(props.item.id);
});

const channelIcon = computed((): string => {
  if (props.item.channel === 'whatsapp') {
    return WhatsAppPng;
  }

  if (props.item.channel === 'live_chat') {
    return props.item.is_idle ? LiveChatIdle : LiveChatActive;
  }

  return '';
});

function handleClick() {
  if (props.hasSelection) {
    inboxStore.toggleSelectedFeedId(props.item.id);

    return;
  }

  router.replace(props.isActive ? '/inbox' : `/inbox/${props.item.id}`);
}

function handleMultiSelect(isChecked: boolean) {
  if (isChecked !== props.isSelected) {
    inboxStore.toggleSelectedFeedId(props.item.id);
  }

  if(router.currentRoute.value.name !== 'inbox') {
    router.replace('/inbox');
  }
}

const isExpiringSoon = computed((): boolean => {
  const conversation = conversations.value[props.item.id];

  if (!conversation) return false;

  const hoursBetween = hoursBetweenNow(conversation.expires_at);

  return hoursBetween > 0 && hoursBetween <= 4;
});

const unrepliedCount = computed((): number => {
  return Expect.integer(counts.value[props.item.id]?.unreplied_count) ?? 0;
});

const temporaryStateAgent = computed(() => {
  if (props.item?.temporaryState?.id) {
    return agentsById.value[props.item.temporaryState.id];
  }
})

const temporaryStatePersona = computed(() => {
  if (!persona.value || !props.item?.temporaryState?.id) return;
  if (persona.value.id !== props.item.temporaryState.id) return;

  return persona.value;
})

const temporaryState = computed((): string => {
  if (!props.item?.temporaryState?.event) return '';

  const name = temporaryStateAgent.value?.firstName || temporaryStatePersona.value?.name || 'Somebody';

  switch(props.item.temporaryState?.event) {
    case 'AGENT_TYPING':
      return  `${name} is typing...`
    case 'AGENT_RECORDING_AUDIO':
      return  `${name} is recording audio...`
    default:
      return `${name} is working...`
  }
});

const lastContent = computed((): string => {
  return temporaryState.value || props.item.last_content;
});
</script>

<template>
  <AppFeedCard
    :is-active="isActive"
    :channel-icon="channelIcon"
    :is-selected="isSelected"
    :is-loading="getLoading"
    :contact-name="contactName"
    :last-content="lastContent"
    :tags="contactTags"
    :is-pinned="isPinned"
    :is-closed="item.is_closed"
    :is-selectable="!disableSelection"
    :last-interaction-date="item.last_interaction_date"
    :unreplied-count="unrepliedCount"
    :is-expiring-soon="isExpiringSoon"
    @click="handleClick"
    @toggle-select="handleMultiSelect"
    @close="inboxStore.requestActionOnFeed(item.id, 'Close')"
    @open="inboxStore.requestActionOnFeed(item.id, 'Open')"
    @pin="agentPreferencesStore.takeActionOnFeed(item.id, 'pin')"
    @unpin="agentPreferencesStore.takeActionOnFeed(item.id, 'unpin')"
  />
</template>
