import { Channel, ChannelInstanceDto, HoolaChannel } from '@/types/channel.types'
import workspace from '@/plugins/axios/workspace'
import { CurrentWorkspace } from '@/utils/current-workspace'
import { notEmpty } from '@/utils/notEmpty'

interface State {
  channels: ChannelInstanceDto[]
  availableChannels: Channel[]
  currentWorkspaceId: string | undefined
}

export const useChannelInstanceStore = defineStore('channelInstanceStore', {
  state: (): State => ({
    channels: [],
    availableChannels: [{ channel: 'whatsapp', metadata: undefined }, { channel: 'live_chat', metadata: undefined }],
    currentWorkspaceId: undefined
  }),
  getters: {
    whatsAppNumber(state): string {
      const channel = state.channels.find(item => item.channel === 'whatsapp');

      if (!channel) {
        return '';
      }

      return `+${channel.externalUserId}`.replaceAll(' ', '');
    },
    allExternalIds(state): string[] {
      return state.channels.map(item => item.externalUserId).filter(notEmpty);
    },
  },
  actions: {
    async getAllChannelInstances(): Promise<ChannelInstanceDto[]> {
      const path = await this.buildPath('channels')
      const { data } = await workspace.get<{ dtos: ChannelInstanceDto[] }>(path, { withCredentials: true })  
      const { dtos } = data

      const availableChannels = this.availableChannels.filter(a => this.channels.find(channel => channel.channel === a.channel) === undefined)

      this.$patch({
        channels: dtos,
        availableChannels: availableChannels,
      });

      return dtos
    },
    async startCreateChannel(channel: HoolaChannel, inputData: any): Promise<void> {
      const path = await this.buildPath('channels')
      await workspace.post<ChannelInstanceDto[]>(path, { channel: channel, data: inputData }, { withCredentials: true })  
    },
    async buildPath(route: string) {
      this.currentWorkspaceId = await CurrentWorkspace.getWorkspace(this.currentWorkspaceId)
      return CurrentWorkspace.buildPath(route, this.currentWorkspaceId)
    },
  },
})
